var exports = {};
exports = bindActor;

function bindActor() {
  var args = Array.prototype.slice.call(arguments) // jswtf.
  ,
      obj = null,
      fn;

  if (typeof args[0] === "object") {
    obj = args.shift();
    fn = args.shift();
    if (typeof fn === "string") fn = obj[fn];
  } else fn = args.shift();

  return function (cb) {
    fn.apply(obj, args.concat(cb));
  };
}

export default exports;