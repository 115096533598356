import _bindActor from "./bind-actor.js";
var exports = {};
exports = chain;
var bindActor = _bindActor;
chain.first = {};
chain.last = {};

function chain(things, cb) {
  var res = [];

  (function LOOP(i, len) {
    if (i >= len) return cb(null, res);
    if (Array.isArray(things[i])) things[i] = bindActor.apply(null, things[i].map(function (i) {
      return i === chain.first ? res[0] : i === chain.last ? res[res.length - 1] : i;
    }));
    if (!things[i]) return LOOP(i + 1, len);
    things[i](function (er, data) {
      if (er) return cb(er, res);
      if (data !== undefined) res = res.concat(data);
      LOOP(i + 1, len);
    });
  })(0, things.length);
}

export default exports;